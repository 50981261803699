<template>
  <footer class="text-center">
    <div class="showOnDesktop">
      <nav class="navbar navbar-expand-lg">
        <div class="container">
          <div class="nav-flex">
            <div class="nav-list-one">
              <div class="mr-5">
                <span class="col-title">Web Menu</span>
                <ul>
                  <li @click="$router.push({ path: '/' })">About Us</li>
                  <li @click="$router.push({ path: '/accomodation' })">Accomodation</li>
                  <li @click="$router.push({ path: '/dining' })">Dining</li>
                  <li @click="$router.push({ path: '/blog' })">Blog</li>
                  <!-- <li style="color: #3D290A;">.</li> -->
                </ul>
              </div>
              <div class="ml-4">
                <span class="col-title">Accommodation</span>
                <ul>
                  <li @click="$router.push({ path: '/accommodation-detail/2' })">Single Bed</li>
                  <li @click="$router.push({ path: '/accommodation-detail/3' })">Twin Bed</li>
                  <li @click="$router.push({ path: '/accommodation-detail/4' })">Triple Bed</li>
                  <li style="color: #3D290A;">.</li>
                </ul>
              </div>
            </div>
            <div class="brand-name">
              <a  @click="$router.push({ path: '/' })">
                <img src="../../assets/locus-logo2.png" width="200px" />
              </a>
            </div>
            <div class="nav-list-two">
              <div class="mr-4" style="text-align: left">
                <span class="col-title">Contact Menu</span>
                <ul>
                  <li @click="$router.push({ path: '/contact-us' })">Contact Us</li>
                  <li @click="$router.push({ path: '/gallery' })">Gallery</li>
                  <li @click="$router.push({ path: 'meetings-and-events' })">Meetings & Events</li>
                  <li style="color: #3D290A">..</li>
                  <li style="color: #3D290A">..</li>
                </ul>
              </div>
              <div class="ml-5" style="text-align: left">
                <span class="col-title">Contacts</span>
                <ul>
                  <li>
                    <i class="far fa-envelope" style="color: #d96b12; padding-right:5px"></i>
                    info@acakilodge.co.ug
                  </li>
                  <li>
                    <i class="fas fa-phone" style="color: #d96b12; padding-right:5px"></i>
                    +256 393 242 288
                  </li>
                  <li>
                    <a href="tel:+256783377001">
                    <i class="fas fa-phone" style="color: #d96b12; padding-right:5px"></i>
                    +256 783 377 001
                    </a>
                  </li>
                  <li>
                    <i class="fas fa-phone" style="color: #d96b12; padding-right:5px"></i>
                    +256 707 377 000
                  </li>
                  <li style="color: white; display: flex; font-size: 1.2em">
                    <div class="mr-2">
                      <a href="https://twitter.com/AcakiLodge"><i class="fab fa-facebook"></i></a>
                    </div>
                    <div class="mr-2">
                      <a href="https://www.facebook.com/acakilodgeuganda"><i class="fab fa-instagram"></i></a>
                    </div>
                    <div class="mr-2">
                      <a href="https://www.instagram.com/acakilodge/"><i class="fab fa-twitter"></i></a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>




    <div class="showOnMobile">
      <div class="container">
        <el-collapse v-model="activeNames" @change="handleChange">

          <el-collapse-item title="Web Menu" name="1">
            <div>
              <ul>
                <li @click="$router.push({ path: '/' })">About Us</li>
                <li @click="$router.push({ path: '/accomodation' })">Accomodation</li>
                <li @click="$router.push({ path: '/dining' })">Dining</li>
                <li @click="$router.push({ path: '/blog' })">Blog</li>
              </ul>
            </div>
          </el-collapse-item>

          <el-collapse-item title="Accomodation" name="2">
            <div>
              <ul>
                <li @click="$router.push({ path: '/accommodation-detail/2' })">Single Bed</li>
                <li @click="$router.push({ path: '/accommodation-detail/3' })">Twin Bed</li>
                <li @click="$router.push({ path: '/accommodation-detail/4' })">Triple Bed</li>
              </ul>
            </div>
          </el-collapse-item>

          <el-collapse-item title="Contact Menu" name="3">
             <div>
                <ul>
                  <li @click="$router.push({ path: '/contact-us' })">Contact Us</li>
                  <li @click="$router.push({ path: '/gallery' })">Gallery</li>
                  <li @click="$router.push({ path: '/meetings-and-events' })">Meetings & Events</li>
                </ul>
            </div>
          </el-collapse-item>

          <el-collapse-item title="Contacts" name="5">
           <ul>
                  <li>
                    <a href="mailto:info@acakilodge .com">
                    <i class="far fa-envelope" style="color: #d96b12; padding-right:5px"></i>
                    info@acakilodge .com
                     </a>
                  </li> <li>
                    <i class="fas fa-phone" style="color: #d96b12; padding-right:5px"></i>
                    +256 393 242 288
                  </li>
                  <li>
                    <a href="tel:+256783377001">
                    <i class="fas fa-phone" style="color: #d96b12; padding-right:5px"></i>
                    +256 783 377 001
                    </a>
                  </li>
                  <li>
                    <i class="fas fa-phone" style="color: #d96b12; padding-right:5px"></i>
                    +256 707 377 000
                  </li>
                
                </ul>
        </el-collapse-item>

        </el-collapse>
        
        <div class="mt-3" style="color: white; display: flex; font-size: 1.2em">
          <div class="mr-2">
                      <a href="https://twitter.com/AcakiLodge"><i class="fab fa-facebook"></i></a>
                    </div>
                    <div class="mr-2">
                      <a href="https://www.facebook.com/acakilodgeuganda"><i class="fab fa-instagram"></i></a>
                    </div>
                    <div class="mr-2">
                      <a href="https://www.instagram.com/acakilodge/"><i class="fab fa-twitter"></i></a>
                    </div>
                  </div>
      </div>
    </div>
    <div class="container" style="margin-top: 50px">
      <!-- <small
        >copyright &copy; 2024 Acaki Lodge  -Uganda | All Rights Reserved</small
      > -->

      <div class="d-flex align-items-center justify-content-between">
            <div>
              <small>copyright &copy; 2024 Acaki Lodge  -Uganda | All Rights Reserved</small>
            </div>
            <div>
              <small>
                Design by <a href="https://www.ovalspace.co" target="_blank">Ovalspace</a>
              </small>
            </div>
          </div>
    </div>
  </footer>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeNames: ['1']
    };
  },

  methods: {
      handleChange(val) {
        console.log(val);
      }
  },
};
</script>

<style scoped>
footer {
  background-color: #3d290a;
  color: grey;
  padding-top: 50px;
  padding-bottom: 50px;
}

a{
  color:gray;
}
footer ul {
  list-style: none;
  padding: 0px;
  margin: 20px 0px 0px 0px;
  text-align: left;
}

footer ul li {
  margin-bottom: 15px;
  cursor: pointer;
}
.nav-flex {
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
.nav-flex > div:first-child,
.nav-flex > div:last-child {
  width: 100%;
}

.nav-flex .nav-list-one {
  list-style: none;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  align-items: center;
}

.nav-flex .nav-list-two {
  list-style: none;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-flex div {
  font-size: 0.9em;
  font-weight: 400;
}

.brand-name {
  margin-left: 50px;
  margin-right: 50px;
}
.col-title {
  color: white;
  font-weight: 500;
  font-size: 1.2em;
  margin-bottom: 50px;
}

.showOnDesktop {
  display: block;
}

.showOnMobile {
  display: none;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  
  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    display: block;
  }

}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    display: block;
  }

}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {

  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    display: block;
  }

}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .nav-flex {
    display: block;
  }
  .brand-name {
    display: none;
  }

  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    display: block;
  }
}
</style>

<style>
  footer .el-collapse-item__header {
    background-color: #3D290A;
    border-bottom: 1px solid #EBEEF5;
    color: #FEF9F6;
    text-align: left;
  }
  footer .el-collapse-item {
    background-color: #3D290A;
    color: #eeeeee;
    text-align: left;
  }
  footer .el-collapse-item__content {
    border: none;
    background-color: #3D290A;
    color: #eeeeee;
    text-align: left;
  }
  footer .el-collapse-item__wrap {
      will-change: height;
      background-color: #3D290A;
      overflow: hidden;
      box-sizing: border-box;
      border-bottom: 1px solid #EBEEF5;
  }
</style>